<template>
  <div class="trip-create" :key="key">
    <v-card width="100%" outlined>
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">Create question</div>
        <div class="text--text">Provide general information about trip.</div>
        <div class="font-weight-bold mt-5 mb-2">Question type</div>
        <v-select
          class="field46"
          v-model="data.question_type"
          :items="typeList"
          placeholder="Select question type"
          item-text="title"
          item-value="name"
          :error-messages="typeErrors"
          outlined
          dense
          color="primary"
          height="46"
        ></v-select>
        <div class="font-weight-bold mb-2">Question</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data.question_en"
            placeholder="Title"
            :error-messages="questionEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 width100 ms-md-3"
            v-model="data.question_ar"
            placeholder="عنوان"
            :error-messages="questionArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-textarea
            class="field46 width100 me-md-3"
            v-model="data.description_en"
            placeholder="Short description"
            :error-messages="desEnErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
          <v-textarea
            class="field46 width100 ms-md-3"
            v-model="data.description_ar"
            placeholder="وصف قصير"
            :error-messages="desArErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
        </div>
        <div>
          <span class="font-weight-bold me-5">Photo content</span>
          <v-switch
            hide-details
            color="primary"
            class="white--text d-inline-block mt-0"
            v-model="data.answer_with_picture"
            inset
          ></v-switch>
        </div>
        <div class="font-weight-bold mt-5 mb-2">Answer</div>
        <div v-for="n in answerLength" :key="n">
          <div :id="n" class="d-flex flex-md-row flex-column multi-direct width100">
            <v-text-field
              class="field46 width100 me-md-3"
              v-model="data[`answer_${n}_en`]"
              placeholder="Answer"
              :error-messages="answerEnErrors(n)"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-text-field>
            <v-text-field
              class="field46 width100 ms-md-3"
              v-model="data[`answer_${n}_ar`]"
              placeholder="عنوان"
              :error-messages="answerArErrors(n)"
              outlined
              dense
              required
              color="primary"
              @change="n == answerLength && answerLength < 6 ? ++answerLength : ''"
              :disabled="!data[`answer_${n}_en`]"
              height="46"
            ></v-text-field>
          </div>
          <v-card
            v-if="data.answer_with_picture"
            flat
            class="primary lighten-3 d-flex align-center justify-center mb-5 mt-n2"
            height="200"
            max-width="360"
          >
            <div v-if="!data[`answer_${n}_image`]">
              <v-img class="mb-3" contain height="48" src="@/assets/icon/img.svg"></v-img>
              <v-btn @click="addPhoto(n)" depressed width="116" height="30" class="primary text-body-2 rounded">Choose photo</v-btn>
            </div>
            <v-img @click="addPhoto(n)" class="rounded-lg link" v-else contain :src="data[`answer_${n}_image`]" height="200"></v-img>
          </v-card>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="text-end py-3 px-10">
        <v-btn @click="create" depressed color="primary" large width="136">Create</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      key: 1,
      answerLength: 2,
      data: {
        answer_with_picture: false,
      },
      typeList: [
        { name: 'single_select', title: 'Single select' },
        { name: 'multi_select', title: 'Multi select' },
      ],
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'create') {
        this.$notify({
          title: 'Done!',
          message: 'Trip question created successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'photo') {
        this.$notify({
          title: 'Missed photo',
          message: 'Each answer must contain photo content. Please check it. ',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    addPhoto(i) {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.data[`answer_${i}_picture`] = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.data[`answer_${i}_image`] = e.target.result;
            ++self.key;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async create() {
      this.error = [];
      const formData = new FormData();
      for (var key in this.data) {
        formData.append(key, this.data[key]);
      }
      await this.$store
        .dispatch('createTripSSQ', formData)
        .then((res) => {
          if (this.$route.query.trip) {
            this.$router.push(`/trips/${this.$route.query.trip}/saudi-surprise`);
          } else {
            this.$router.push(`/saudi-surprise/${res.data.id}`);
          }
          this.notifi('create');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    answerEnErrors(i) {
      const errors = [];
      this.error.find((item) => item == `answer_${i}_en__required`) && errors.push('Please enter answer');
      this.error.find((item) => item == `answer_${i}_en__invalid`) && errors.push('Provided answer is not valid');
      return errors;
    },
    answerArErrors(i) {
      const errors = [];
      this.error.find((item) => item == `answer_${i}_ar__required`) && errors.push('Please enter answer');
      this.error.find((item) => item == `answer_${i}_ar__invalid`) && errors.push('Provided answer is not valid');
      return errors;
    },
  },
  watch: {
    error(val) {
      if (val) {
        const photo = this.error.length ? this.error[0].split('__')[0].substr(-7, 7) : '';
        photo == 'picture' ? this.notifi('photo') : '';
      }
    },
  },
  computed: {
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'question_type__required') && errors.push('Select enter question type');
      this.error.find((item) => item == 'question_type__invalid') && errors.push('Selected question type is not valid');
      return errors;
    },
    questionEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'question_en__required') && errors.push('Please enter question');
      this.error.find((item) => item == 'question_en__invalid') && errors.push('Provided question is not valid');
      return errors;
    },
    questionArErrors() {
      const errors = [];
      this.error.find((item) => item == 'question_ar__required') && errors.push('Please enter question');
      this.error.find((item) => item == 'question_ar__invalid') && errors.push('Provided question is not valid');
      return errors;
    },
    desEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    desArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.trip-create {
  .v-input {
    max-width: 371px;
  }
}
</style>
